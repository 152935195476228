import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import ManageCookies from "../components/manage-cookies/manage-cookies";

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        pagename
        title
        manage_cookies
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

function PolicyTemplate({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout pagename={post.frontmatter.pagename}>
      <SEO title={post.frontmatter.title} />
      <div style={{ maxWidth: "75ch" }} className="page_content">
        <h3>{post.frontmatter.title}</h3>
        <div
          className="reading_content"
          style={{ marginTop: "1.718rem" }}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.manage_cookies && <ManageCookies />}
      </div>
    </Layout>
  );
}

export default PolicyTemplate;
