import React, { useEffect, useState } from "react";
import { Trans } from "gatsby-plugin-react-i18next";

import useCookie from "../useCookie";
import "./manage-cookies.styl";

const ManageCookies = () => {
  const [gaCookie, setGACookie] = useCookie("gdpr-google-analytics", "");

  const [requestState, setRequestState] = useState("idle");
  const [cookiesAllowed, setCookiesAllowed] = useState(false);

  const onClick = () => {
    setRequestState("saving");
    setTimeout(() => setRequestState("saved"), 2000);

    if (cookiesAllowed) {
      setGACookie("true");
    } else {
      setGACookie("false");
    }
  };

  useEffect(() => {
    setCookiesAllowed(gaCookie === "true");
  }, [gaCookie, setCookiesAllowed]);

  return (
    <div className="manage-cookies" id="manage-cookies">
      <h3>
        <Trans>Gestiona tus cookies</Trans>
      </h3>
      <form className="cookie_form" name="manage_cookies">
        <div className="cookie_form__field">
          <h4 className="cookie_form__field_title">
            <Trans>Cookies internas</Trans>
          </h4>
          <p>
            <Trans>
              Utilizamos las cookies internas para guardar tus preferencias de
              navegación, incluidas tus preferencias de uso de cookies.
            </Trans>
            <br />
            <Trans>
              Las cookies internas son necesarias para el funcionamento básico
              de esta web.
            </Trans>
          </p>
          <input type="checkbox" name="own-cookies" checked={true} disabled />
          <label htmlFor="own-cookies">
            <Trans>Habilitar cookies internas</Trans>
          </label>
        </div>
        <div className="cookie_form__field">
          <h4 className="cookie_form__field_title">
            <Trans>Cookies de análisis</Trans>
          </h4>
          <p>
            <Trans>
              Utilizamos cookies de Google Analytics para hacer un rastreo
              anonimizado del tráfico por nuestra web
            </Trans>
          </p>
          <input
            type="checkbox"
            name="analytics-cookies"
            checked={cookiesAllowed}
            onChange={(event) => setCookiesAllowed(event.target.checked)}
          />
          <label htmlFor="analytics-cookies">
            <Trans>Habilitar cookies de análisis</Trans>
          </label>
        </div>
        <button
          className={`button button--${requestState}`}
          type="button"
          onClick={onClick}
        >
          {requestState === "idle" && <Trans>Guardar</Trans>}
          {requestState === "saved" && (
            <svg class="animated-check" viewBox="0 0 24 24">
              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
            </svg>
          )}
        </button>
      </form>
    </div>
  );
};

export default ManageCookies;
